<template>
  <div>
    <v-container fluid class="py-0 pb-3">
      <div class="py-3" style="width: 300px">
        <v-text-field
          hide-details
          outlined
          class="base-text-field"
          label="Поиск"
          append-outer-icon="mdi-magnify"
        />
      </div>

      <v-btn
        tile
        elevation="0"
        color="green"
        class="white--text  text-capitalize"
        height="27"
        @click="createUser"
      >
        <span>Создать</span>
      </v-btn>
    </v-container>
    <div>
      <v-data-table
        fixed-header
        dense
        class="base-table"
        :headers="headers"
        :items="userList"
        height="68vh"
        no-data-text="Нет данных для отображения"
      >
        <template v-slot:item.update_timestamp="{ item }">
          {{ formatDate(item.update_timestamp) }}
        </template>
        <!-- roles input -->
        <template v-slot:item.roles="{ item }">
          <div v-for="role in item.roles" :key="role.id">
            {{ role.name }}
          </div>
        </template>
        <template v-slot:item.actions="{ item }">
          <v-icon small @click="editItem(item)" class="mr-3">
            mdi-pencil
          </v-icon>
          <v-icon small color="red" @click="deleteUser(item)">
            mdi-delete
          </v-icon>
        </template>
      </v-data-table>
    </div>
    <dialog-alert ref="dialogAlert" />
    <dialog-wrapper
      ref="dialogCrud"
      :title="
        `${typeDialog === 'add' ? 'Создание' : 'Редактирование'} пользователя`
      "
    >
      <!-- :data="editedItem" -->
      <form-user
        v-model="editedItem"
        :isEditMode="typeDialog === 'add' ? false : true"
      />
    </dialog-wrapper>
    <v-snackbar v-model="isShowInfo" top color="white">
      <span class="black--text">
        {{ infoAlert }}
      </span>
    </v-snackbar>
  </div>
</template>

<script>
import DialogWrapper from "./DialogWrapper.vue";
import DialogAlert from "@/components/Dialogs/DialogAlert.vue";
import FormUser from "./FormUser.vue";
import { dateFormater } from "@/utils/dateFormater.js";

export default {
  components: { DialogAlert, DialogWrapper, FormUser },
  name: "UserTab",
  data() {
    const typeDialog = "add";
    const headers = [
      {
        text: "Логин",
        value: "login",
      },
      {
        text: "Почта",
        value: "email",
      },
      {
        text: "ФИО",
        value: "full_name",
      },
      {
        text: "Роли",
        value: "roles",
      },
      {
        text: "Последняя дата входа",
        value: "update_timestamp",
      },
      {
        text: "Actions",
        value: "actions",
        sortable: false,
      },
    ];
    let editedItem = {};
    return {
      typeDialog,
      headers,
      editedItem,
      infoAlert: "",
      isShowInfo: false,
    };
  },
  mounted() {
    this.$store.dispatch("getAllUsersAccounts");
  },
  methods: {
    createPayload(editedItem) {
      return {
        ...(editedItem.id ? { id: editedItem.id } : {}),
        full_name: editedItem.full_name,
        email: editedItem.email,
      };
    },
    changePassword() {},
    changeRoles() {},
    editItem(item) {
      this.typeDialog = "edit";

      this.editedItem = {};
      this.editedItem = Object.assign({}, item);
      this.$refs.dialogCrud.open().then((result) => {
        if (result) {
          this.infoAlert = `Пользователь ${this.editedItem.login} успешно обновлен`;
          this.isShowInfo = true;
        }
      });
    },
    createUser() {
      this.typeDialog = "add";
      this.editedItem = {};
      this.editedItem = Object.assign({});

      this.$refs.dialogCrud.open().then((result) => {
        if (result) {
          this.infoAlert = `Пользователь ${this.editedItem.login} успешно создан`;
          this.isShowInfo = true;
        }
      });
    },
    deleteUser(user) {
      this.$store.dispatch(
        "callAlert",
        `Вы действительно хотите удалить пользователя ${user.full_name}`
      );
      this.$refs.dialogAlert.open().then((res) => {
        if (res) {
          this.$store.dispatch("deleteUser", user.id).then(() => {
            this.infoAlert = `Пользователь ${this.editedItem.login} успешно удален`;
            this.isShowInfo = true;
          });
        }
      });
    },
    formatDate(date) {
      return dateFormater(date);
    },
  },
  computed: {
    userList() {
      return this.$store.getters.getUsersAccountsList;
    },
  },
};
</script>
