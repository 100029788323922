<template>
  <v-form>
    <div>
      <v-row no-gutters class="mb-4">
        <v-col cols="2" class="d-flex align-center">
          <span
            >Тип пользователя<span v-if="!isEditMode" style="color:red"
              >*</span
            ></span
          >
        </v-col>
        <v-select
          dense
          hide-details
          outlined
          :readonly="isEditMode"
          :disabled="isEditMode"
          class="base-select"
          v-model="dataForm.type"
          @change="onChangeTypeUser"
          :items="[
            {
              name: 'Доменный',
              value: 'DOMAIN',
            },
            {
              name: 'Локальный',
              value: 'LOCAL',
            },
          ]"
          item-text="name"
          item-value="value"
        >
        </v-select>
      </v-row>
    </div>

    <!-- Select type user Domain -->
    <div v-if="dataForm.type === 'DOMAIN'">
      <div>
        <error-info :error="'full_name' in errorsValidation">
          {{ errorsValidation.full_name }}
        </error-info>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span
              >Ф.И.О<span v-if="!isEditMode" style="color:red">*</span></span
            >
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataForm.full_name"
              autofocus
              dense
              hide-details
              outlined
              class="base-text-field"
            />
          </v-col>
        </v-row>
      </div>
      <div>
        <error-info :error="'email' in errorsValidation">
          {{ errorsValidation.email }}
        </error-info>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span>Почта</span>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataForm.email"
              dense
              hide-details
              outlined
              class="base-text-field"
            />
          </v-col>
        </v-row>
      </div>
      <div>
        <error-info :error="'login' in errorsValidation">
          {{ errorsValidation.login }}
        </error-info>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span
              >Логин<span v-if="!isEditMode" style="color:red">*</span></span
            >
          </v-col>
          <v-col>
            <v-autocomplete
              dense
              hide-details
              outlined
              :disabled="isEditMode"
              v-model="dataForm.login"
              class="base-auto-complete"
              :items="domainUser"
            />
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span>Роли</span>
          </v-col>
          <v-col>
            <v-select
              multiple
              :items="rolesList"
              v-model="dataForm.roles"
              item-text="name"
              dense
              return-object
              hide-details
              outlined
              class="base-select"
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <!-- Select type user Local -->
    <div v-if="dataForm.type === 'LOCAL'">
      <div>
        <error-info :error="'full_name' in errorsValidation">
          {{ errorsValidation.full_name }}
        </error-info>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span
              >Ф.И.О<span v-if="!isEditMode" style="color:red">*</span></span
            >
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataForm.full_name"
              autofocus
              dense
              hide-details
              outlined
              class="base-text-field"
            />
          </v-col>
        </v-row>
      </div>

      <div>
        <error-info :error="'email' in errorsValidation">
          {{ errorsValidation.email }}
        </error-info>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span>Почта</span>
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataForm.email"
              dense
              hide-details
              outlined
              class="base-text-field"
            />
          </v-col>
        </v-row>
      </div>

      <div>
        <error-info :error="'login' in errorsValidation">
          {{ errorsValidation.login }}
        </error-info>
        <error-info :error="'login' in globalError">
          {{ globalError.login }}
        </error-info>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span
              >Логин<span v-if="!isEditMode" style="color:red">*</span></span
            >
          </v-col>
          <v-col>
            <v-text-field
              v-model="dataForm.login"
              dense
              hide-details
              outlined
              class="base-text-field"
              :readonly="isEditMode"
              :disabled="isEditMode"
            />
          </v-col>
        </v-row>
      </div>

      <div>
        <error-info :error="'password' in errorsValidation">
          {{ errorsValidation.password }}
        </error-info>
        <v-row no-gutters class="mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span
              >Пароль<span v-if="!isEditMode" style="color:red">*</span></span
            >
          </v-col>
          <v-col>
            <v-text-field
              type="password"
              v-model="dataForm.password"
              dense
              hide-details
              outlined
              class="base-text-field"
            />
          </v-col>
        </v-row>
      </div>

      <div>
        <v-row no-gutters class="d-flex align-center mb-4">
          <v-col cols="2" class="d-flex align-center">
            <span
              >Подтвердите пароль<span v-if="!isEditMode" style="color:red"
                >*</span
              ></span
            >
          </v-col>
          <v-col>
            <v-text-field
              type="password"
              dense
              hide-details
              outlined
              class="base-text-field"
              v-model="confirmPassword"
            />
          </v-col>
        </v-row>
      </div>
      <div>
        <v-row no-gutters class="mb-4">
          <v-col cols="2">
            <span>Роли</span>
          </v-col>
          <v-col>
            <v-select
              multiple
              :items="rolesList"
              v-model="dataForm.roles"
              item-text="name"
              dense
              return-object
              hide-details
              outlined
              class="base-select"
            />
          </v-col>
        </v-row>
      </div>
    </div>

    <v-card-actions>
      <v-spacer />
      <v-btn
        tile
        elevation="0"
        dense
        color="green"
        class="white--text text-capitalize"
        @click="onSubmitForm"
      >
        Сохранить
      </v-btn>
      <v-btn
        tile
        color="lightBlue"
        elevation="0"
        dense
        @click="onRejectForm"
        class="text-capitalize white--text"
      >
        Закрыть
      </v-btn>
    </v-card-actions>
  </v-form>
</template>

<script>
import ErrorInfo from "../Dialogs/ErrorInfo.vue";

export default {
  components: { ErrorInfo },
  name: "FormUser",
  props: ["value", "isEditMode"],
  inject: ["agree", "cancel"],
  data() {
    return {
      confirmPassword: "",
      errorsValidation: {},
      SelectedTypeUser: "",
      globalError: {},
    };
  },
  mounted() {
    this.$store.dispatch("getAllRoles");
    this.$store.dispatch("fetchDomainUsersCN");
  },
  methods: {
    createPayloadLocalUser(editedItem) {
      const roles = editedItem.roles?.map((item) => item.id) ?? [];
      return {
        ...editedItem,
        roles,
      };
    },
    createPayloadDomainUser(editedItem) {
      const roles = editedItem.roles?.map((item) => item.id) ?? [];
      return {
        ...editedItem,
        cn: editedItem.login,
        roles,
      };
    },
    onSubmitForm() {
      if (this.isEditMode) this.updateUser(this.dataForm);
      else this.createUser(this.dataForm);
    },
    createUser(data) {
      this.globalError = {};

      if (data.type === "LOCAL" && !this.validateLocal()) return;
      if (data.type === "DOMAIN" && !this.validateDomain()) return;

      if (data.type === "LOCAL") {
        this.$store
          .dispatch("createNewUserLocale", this.createPayloadLocalUser(data))
          .then(() => {
            this.agree();
          })
          .catch((err) => {
            console.log(err.response.status);
            if (err.response.status === 409) {
              this.globalError.login = "Логин уже используется";
            }
          })
          .finally(() => {
            this.confirmPassword = "";
          });
      }

      if (data.type === "DOMAIN") {
        this.$store
          .dispatch("createNewUserDomain", this.createPayloadDomainUser(data))
          .then(() => {
            this.agree();
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      }
    },
    updateUser(data) {
      if (this.dataForm.type === "LOCAL" && !this.validateLocal()) return;
      if (this.dataForm.type === "DOMAIN" && !this.validateDomain()) return;

      if (data.type === "LOCAL") {
        this.$store
          .dispatch("updateUser", this.createPayloadLocalUser(data))
          .then(() => {
            this.$store
              .dispatch("updateUserRoles", this.createPayloadDomainUser(data))
              .then(() => {
                this.agree();
              });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            this.confirmPassword = "";
          });
      }
      if (data.type === "DOMAIN") {
        this.$store
          .dispatch("updateUser", this.createPayloadDomainUser(data))
          .then(() => {
            this.$store
              .dispatch("updateUserRoles", this.createPayloadDomainUser(data))
              .then(() => {
                this.agree();
              });
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {});
      }
    },
    onRejectForm() {
      this.errorsValidation = {};
      this.confirmPassword = "";
      this.cancel();
    },
    validateDomain() {
      this.errorsValidation = {};
      if (!this.dataForm.login) {
        this.errorsValidation.login = "Выберите пользователя из AD";
      }

      if (Object.keys(this.errorsValidation).length === 0) return true;

      if (!this.dataForm.full_name) {
        this.errorsValidation.full_name = "Введите ФИО пользователя";
      }
      return false;
    },
    validateLocal() {
      this.errorsValidation = {};
      if (
        !this.dataForm.login ||
        this.dataForm.login.length < 3 ||
        this.dataForm.login.length > 100
      ) {
        this.errorsValidation.login = "Логин должен быть от 3 до 100 символов";
      }

      if (
        !this.isEditMode ||
        (!!this.dataForm.password && this.dataForm.password.length > 0)
      ) {
        if (this.dataForm.password !== this.confirmPassword) {
          this.errorsValidation.password = "Пароли не совпадают";
        }
        const regexPasswd = /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*]).{8,}$/;

        if (!regexPasswd.test(this.dataForm.password)) {
          this.errorsValidation.password =
            "Пароль должен соддежрать 8 символов, 1 символ в врехнем регистре, 1 символ в нижнем регистре, 1 спецсимвол (!@#$%^&*)";
        }
      }

      if (!this.dataForm.full_name) {
        this.errorsValidation.full_name = "Введите ФИО пользователя";
      }

      if (!!this.dataForm.email && this.dataForm.email.length > 0) {
        if (
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(
            this.dataForm.email
          )
        ) {
          //
        } else {
          this.errorsValidation.email = "Неверный формат почты";
        }
      }
      console.log(this.errorsValidation);
      if (Object.keys(this.errorsValidation).length === 0) return true;

      return false;
    },
    onChangeTypeUser() {},
  },

  computed: {
    rolesList() {
      return this.$store.getters.getRolesList;
    },
    dataForm: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    domainUser() {
      // if (!this.isEditMode) {
      return this.$store.getters.getDomainUsers.map((item) => item.cn);
      // }
      // return [];
    },
  },
};
</script>
