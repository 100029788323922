export const dateFormater = (date) => {
  const padL = (nr, chr = `0`) => `${nr}`.padStart(2, chr);
  const dt = new Date(date);
  // const options = {
  //   hour: "2-digit",
  //   minutes: "2-digit",
  //   second: "2-digit",
  //   day: "2-digit",
  //   month: "long",
  //   year: "numeric",
  // };
  // console.log(
  //   `${padL(dt.getMonth() + 1)}.${padL(
  //     dt.getDate()
  //   )}.${dt.getFullYear()} ${padL(dt.getHours())}:${padL(
  //     dt.getMinutes()
  //   )}:${padL(dt.getSeconds())}`
  // );

  // console.log("date = ", result);
  return `${padL(dt.getDate())}.${padL(
    dt.getMonth() + 1
  )}.${dt.getFullYear()} ${padL(dt.getHours())}:${padL(dt.getMinutes())}:${padL(
    dt.getSeconds()
  )}`;
  // return dt;
};
